import React from "react";
import Helmet from "react-helmet";
import styled from 'styled-components';
import cssVars from 'css-vars-ponyfill';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";

import image from "@images/about.png";
import logo from "@images/logo-h.png";

cssVars({});
export default class PortofolioLayout extends React.Component {
    render() {
        const { children, title } = this.props;
        return (
            <div>
                <Helmet
                    title="Adrian-Gabriel Manduc | Fullstack Developer"
                    meta={[
                        { name: 'description', content: 'Fullstack developer based in Bucharest, Romania. Creating high quality, beautifully crafted web and mobile experiences' },
                        { name: 'keywords', content: 'adrian, manduc, adrian-gabriel, adrian manduc, adi, adi manduc, adrian-gabriel manduc, fullstack, developer, frontend, ux, ui, bucharest, romania, mobile, app, UI/UX, design, programming' },
                        { name: 'og:description', content: 'Fullstack developer based in Bucharest, Romania. Creating high quality, beautifully crafted web and mobile experiences'},
                        { name: 'og:image', content: image}
                    ]}
                />
                <Navbar>
                   <LinkWrapper>
                        <Link to="/#portofolio">
                            <button>
                                <FontAwesomeIcon icon={'chevron-left'}/>
                                <span>Home</span>
                            </button>
                        </Link>
                   </LinkWrapper>
                    <h1 className="title">
                        {title}
                    </h1>
                    {
                        isMobile ? <Logo/> :  <img src={logo} alt="Logo"/>
                    }
                </Navbar>
                {children}
            </div>
        );
    }
}

const Logo = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259.18 185.4">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path className="cls-1" d="M259.18,185.4v0L151.92,0,129.57,38.63,107.22,0,0,185.33l23.91-.05,83.31-144.1,10.44,17.93L44.57,185.38H68.49l12.06-20.87,12.3-21.29h73.42l24.44,42.16h23.82l-.19-.33,42.28.33Zm-56.81-20.81-52.28-90.5L138.17,94.55l16.27,28H104.72L151.92,41,223.3,164.49Z"/>
                </g>
            </g>
        </svg>
    )
}

const LinkWrapper = styled.div`
    width: 144px;
    display: flex;
    align-items: center;

    @media (max-width: 767px){
        width: auto;
    }
`

const Navbar = styled.header`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 16px 24px;
    z-index: 99;
    background: #fff;
    box-shadow: 0 1px 8px 3px rgba(0,0,0,0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }

    .title{
        flex-grow: 1;
        text-align: center;
        font-weight: 600;
        color: #000;
    }

    button{
        background: none;
        border: 0;
        box-shadow: none;
        outline: 0;
        cursor: pointer;

        span{
            margin-left: 8px;
            font-size: 16px;
            font-weight: 600;
        }

        &:hover {
            color: var(--primary);
        }
    }

    img{
        height: 33px;
    }

    @media (max-width: 767px){

        a {
            width: auto;
        }

        img {
            display: none;
        }

        button {
            span{
                display: none;
            }
        }

        .title{
            text-align: left;
            font-size: 16px;
            padding: 0px 24px;
        }

        svg{
            height: 22px;
        }
    }
`